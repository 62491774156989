var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("header", { staticClass: "header header--lg" }, [
      _c("div", { staticClass: "container container--sm" }, [
        _c("div", { staticClass: "artist-type__wrapper" }, [
          _vm.playlist && _vm.playlist.image_thumbnail_url
            ? _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.playlist.image_thumbnail_url,
                    expression: "playlist.image_thumbnail_url",
                  },
                ],
                staticClass: "artist-type__img-playlist",
                attrs: { alt: "Playlist Image" },
              })
            : _vm._e(),
          _c("div", { staticClass: "artist-type__info" }, [
            _c("h2", { staticClass: "h2--small-uppercase" }, [
              _vm._v("Playlist"),
            ]),
            _c("h1", { staticClass: "artist-type__h1" }, [
              _vm._v(_vm._s(_vm.playlist ? _vm.playlist.name : "Loading...")),
            ]),
            _c(
              "a",
              {
                staticClass: "btn btn--white",
                staticStyle: { "margin-top": "0px" },
                on: {
                  click: function ($event) {
                    return _vm.toggleFollowPlaylist(_vm.playlist.id)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.playlistIsInFollowedPlaylists ? "Following" : "Follow"
                  )
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("BeatTable", {
            attrs: {
              parentState: _vm.ParentStateType.Playlist,
              beatPlaylist: _vm.beats,
            },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.beats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }