

















import Vue from 'vue'
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'

import BeatTable from '@/components/BeatTable.vue'
import { fetchSinglePlaylist } from '@/api/playlists'
import { BeatFilterType, fetchBeats } from '~/api/beats'
import { UserStore } from '@/store/user'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { AuthStore } from '~/store/auth'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable } })
export default class PlaylistPage extends Mixins(InfiniteLoadingSetState) {
    @Prop({ type: Number }) id!: number

    ParentStateType = ParentStateType
    playlist: IPlaylist = null
    beats: IBeat[] = []

    @UserStore.State('authenticatedUser') authenticatedUser: IUser
    @UserStore.State('followedPlaylists') followedPlaylists: IFollowedPlaylist[]
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

    get playlistIsInFollowedPlaylists(): boolean {
        // if this current playlist is part of the user's followedPlaylists. Returns obj or undefined
        return this.isAuthenticated && this.followedPlaylists.find(item => item.playlist.id === this.id) ? true : false
    }

    @UserStore.Action('toggleFollowPlaylist') toggleFollowPlaylist: (playlistId: IPlaylist['id']) => void

    async updateBeatsTable($state: any, offset: number) {
        // call the store to update the beats
        const beats = await fetchBeats({ filterType: BeatFilterType.Playlist, playlistId: this.id, offset })
        this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    async fetchEverything() {
        this.playlist = await fetchSinglePlaylist(this.id)
        this.updateBeatsTable(null, 0)
    }

    @Watch('id')
    onIdChange(newValue: number) {
        // reset beats list
        this.beats = []
        this.fetchEverything()
    }

    mounted() {
        this.fetchEverything()
    }
}
