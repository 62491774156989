import { get } from '~/services/http-requests'

const _BS_PLAYLISTS = 'playlists'
const _BS_PLAYLISTS_RANDOM = 'random_playlists'

export enum PlaylistFilterType {
    ArtistType,
    Genre = 'genres'
}

interface IPlaylistFilterParams {
    filterType: PlaylistFilterType
    filterId: number
}

/*
 **** GET ****
 */
async function fetchSinglePlaylist(playlistId: IPlaylist['id']): Promise<IPlaylist> {
    const [first] = await get({ baseProperty: _BS_PLAYLISTS, fullUrl: `${_BS_PLAYLISTS}.json?id=${playlistId}` })
    return first
}

function fetchAllPlaylists(): Promise<IPlaylist[]> {
    return get({ baseProperty: _BS_PLAYLISTS })
}

function fetchPlaylistsRandom(): Promise<IPlaylist[]> {
    return get({ baseProperty: _BS_PLAYLISTS_RANDOM })
}

function fetchPlaylistsWithFilter({ filterType, filterId }: IPlaylistFilterParams): Promise<IPlaylist[]> {
    return get({ baseProperty: _BS_PLAYLISTS, fullUrl: `${_BS_PLAYLISTS}.json?${filterType}__in=${filterId}` })
}

export { fetchSinglePlaylist, fetchAllPlaylists, fetchPlaylistsRandom, fetchPlaylistsWithFilter }
